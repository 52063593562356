<div class="page px-8 lg:px-0 pb-4 lg:pb-[100px]" *ngIf="!loading">
  <div
    class="bread-crumb capitalize text-sm self-start font-bold text-black py-2 lg:text-[20px] lg:py-10 lg:px-8 overflow-x-auto whitespace-nowrap"
  >
    <span
      class="cursor-pointer bg-gray-300 rounded-3xl py-3 px-5 float-none"
      [routerLink]="'/productos'"
      [queryParams]="{ cid: '', sort: sort, page: cid === '' ? page : 0 }"
      >Todos</span
    >
    <span
      *ngFor="let cate of product.categories"
      class="cursor-pointer bg-gray-300 rounded-3xl py-3 px-5 float-none"
      [routerLink]="['/productos']"
      [queryParams]="{
        cid: cate._id,
        sort: sort,
        page: cid === cate._id ? page : 0
      }"
      >{{ cate.name | titlecase }}</span
    >
  </div>

  <div class="h-full w-full flex flex-col lg:flex-row overflow-y-scroll">
    <div @fade class="w-full pb-2 lg:hidden relative">
      <swiper-container
        swiperElement
        #swiper
        [config]="config"
        init="false"
        class="pb-8"
      >
        <swiper-slide *ngFor="let url of product.image_url; let i = index">
          <img
            data-src="{{ convertToImageKitUrl(url, null, '361') }}"
            [alt]="product.name"
            data-sizes="auto"
            [ngClass]="{ lazyload: true, show: image.complete }"
            #image
            [id]="i"
            class="w-full max-w-[361px] object-cover lazyload slide h-[350px]"
          />
          <div
            *ngIf="product.show_in_store && !product.active"
            class="w-2/6 absolute top-0 left-0 bg-secondary text-black text-lg text-center font-bold py-2 cursor-not-allowed"
          >
            Agotado
          </div>
        </swiper-slide>
      </swiper-container>
      <!-- <swiper [slidesPerView]="1" [spaceBetween]=" 0" [pagination]="{type: 'bullets', clickable: true} "
                [virtual]="true">
                
            </swiper> -->
    </div>
    <div class="w-full hidden lg:flex lg:flex-1 gap-4 px-8" *ngIf="!loading">
      <div
        class="rounded-lg overflow-hidden h-[386px] xl:h-[500px] w-[386px] xl:w-[500px] flex items-center justify-center bg-white relative"
      >
        <img
          data-src="{{
            convertToImageKitUrl(activeImageUrl, getImageRenderSize())
          }}"
          [alt]="product.name"
          data-sizes="auto"
          [ngClass]="{ lazyload: true, show: image.complete }"
          #image
          [id]="product._id"
          class="h-[386px] xl:h-[500px] w-auto object-contain lazyload slide"
        />
        <div
          *ngIf="product.show_in_store && !product.active"
          class="w-2/6 absolute top-0 left-0 bg-secondary text-black text-lg text-center font-bold py-2 cursor-not-allowed"
        >
          Agotado
        </div>
      </div>
      <div class="flex flex-col items-center h-[386px] gap-5">
        <div
          class="rounded-lg overflow-hidden h-[85px] w-[85px] flex items-center justify-center bg-white cursor-pointer"
          *ngFor="let url of product.image_url; let i = index"
        >
          <img
            data-src="{{ convertToImageKitUrl(url, '85') }}"
            [alt]="product.name"
            data-sizes="auto"
            [ngClass]="{ lazyload: true, show: image.complete }"
            #image
            [id]="i"
            class="h-[85px] w-auto object-contain lazyload slide"
            (click)="activeImageUrl = url"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-col lg:flex-1 lg:px-8 lg:items-center"
      *ngIf="!loading"
    >
      <div class="flex flex-col w-full lg:w-[371px] gap-2">
        <div class="flex flex-col w-full gap-4">
          <span class="font-bold text-[20px]">{{ product.name }}</span>
          <div
            class="text-[14px] text-justify max-h-[100px] overflow-hidden cursor-pointer inner-shadow transition-all ease-in duration-100 select-none"
            id="description"
            (click)="styler.toggleDescription()"
          >
            <p>
              {{ product.description }}
            </p>
          </div>

          <span class="text-primary font-bold text-[20px]">{{
            product.price | customCurrency
          }}</span>
        </div>

        <div class="pb-8 flex flex-col w-full gap-4" *ngIf="hasVariant">
          <div
            class="w-full border border-slate-300 rounded-xl flex flex-col transition-all duration-100 pb-4 select-none"
          >
            <div
              class="w-full flex flex-col justify-between items-left px-4 pt-4"
              *ngFor="let optionType of product.options"
            >
              <div class="flex items-center justify-between gap-4 w-full">
                <div class="flex gap-2 p-2">
                  <span class="font-bold text-[14px] capitalize">{{
                    optionType.name
                  }}</span>
                </div>
              </div>
              <div class="w-full flex flex-wrap gap-2">
                <div *ngFor="let option of optionType.Options; let i = index">
                  <input
                    [checked]="i === 0"
                    type="radio"
                    id="{{ optionType.name }}-{{ option.id }}"
                    name="{{ optionType.name }}"
                    value="{{ option.id }}"
                    class="hidden peer"
                    required
                  />
                  <label
                    (click)="
                      addOption(option.id, optionType.name, option.value)
                    "
                    for="{{ optionType.name }}-{{ option.id }}"
                    class="inline-flex items-center justify-between px-5 py-1 text-gray-500 bg-white border border-gray-500 rounded-3xl cursor-pointer peer-checked:bg-black peer-checked:text-white hover:text-white hover:bg-black"
                  >
                    {{ option.value }}
                  </label>
                </div>
              </div>
            </div>
            <label
              class="p-4 text-red-400"
              *ngIf="
                !hasStock && selectedOptions.length === product.options.length
              "
              >No hay stock para esa combinación*</label
            >
          </div>
        </div>

        <div class="pb-8 flex flex-col w-full gap-4">
          <ng-container *ngFor="let extraCategory of categoriesWithExtras">
            <div
              class="w-full border border-slate-300 rounded-xl flex flex-col transition-all duration-100 pb-4 select-none"
              [ngClass]="{
                'border-primary border-2': extraCategory.category.min
                  ? getExtraCategorySelectedExtrasQuantity(
                      extraCategory.category._id
                    ) >= extraCategory.category.min
                  : getExtraCategorySelectedExtrasQuantity(
                      extraCategory.category._id
                    ) > 0
              }"
              [id]="extraCategory.category._id"
            >
              <div
                class="w-full flex flex-col justify-between items-center cursor-pointer px-4 pt-4"
                [id]="extraCategory.category._id + '-accordion'"
                (click)="styler.toggleExtraCategory(extraCategory.category._id)"
              >
                <div class="flex items-center justify-between gap-4 w-full">
                  <div class="flex gap-2">
                    <span class="font-bold text-[14px] capitalize">{{
                      extraCategory.category.name
                    }}</span>
                    <span class="text-[14px] text-black">
                      <ng-container
                        *ngIf="
                          extraCategory.category.min > 0 &&
                            extraCategory.category.min !==
                              extraCategory.category.max;
                          else single
                        "
                      >
                        <span class="">
                          {{
                            "Escoge entre " +
                              extraCategory.category.min +
                              " y " +
                              extraCategory.category.max +
                              " opciones."
                          }}
                        </span>
                      </ng-container>
                      <ng-template #single>
                        <ng-container
                          *ngIf="
                            extraCategory.category.min ===
                              extraCategory.category.max;
                            else optional
                          "
                        >
                          <span class="">
                            {{
                              "Escoge " +
                                extraCategory.category.max +
                                (extraCategory.category.max > 1
                                  ? " opciones."
                                  : " opción")
                            }}
                          </span>
                        </ng-container>
                      </ng-template>
                      <ng-template #optional>
                        <span>(Opcional)</span>
                      </ng-template>
                    </span>
                  </div>
                  <i
                    [id]="extraCategory.category._id + '-arrow'"
                    class="fa-sharp fa-solid fa-chevron-down transition-all ease-in duration-200"
                  ></i>
                  <i
                    [id]="extraCategory.category._id + '-info'"
                    class="fa-sharp fa-solid fa-circle-info hidden text-red-400"
                  ></i>
                </div>
                <span class="w-full text-ellipsis overflow-hidden h-[24px]">
                  <ng-container
                    *ngFor="
                      let selectedExtra of getExtraCategorySelectedExtras(
                        extraCategory.category._id
                      )
                    "
                  >
                    <span
                      *ngIf="selectedExtra.quantity > 0"
                      class="text-[14px] text-slate-400"
                      >{{
                        selectedExtra.name +
                          " x" +
                          selectedExtra.quantity +
                          ", "
                      }}</span
                    >
                  </ng-container>
                  <span
                    *ngIf="
                      getExtraCategorySelectedExtrasQuantity(
                        extraCategory.category._id
                      ) === 0
                    "
                    class="text-[14px] text-slate-400"
                  >
                    No has seleccionado ningún extra.
                  </span>
                </span>
              </div>
              <div
                class="w-full overflow-hidden transition-all ease-in duration-200 px-4 open"
                [id]="extraCategory.category._id + '-panel'"
              >
                <section class="flex flex-col gap-2 pt-4">
                  <div
                    class="w-full flex items-center justify-between"
                    *ngFor="let extra of extraCategory.extras; let i = index"
                  >
                    <span
                      class="flex items-center gap-4"
                      *ngIf="isActive(extra)"
                    >
                      <span
                        class="text-black text-sm max-w-[150px] text-ellipsis overflow-hidden"
                        >{{ extra.name }}</span
                      >
                      <span
                        class="text-primary text-base font-bold"
                        *ngIf="extra.price > 0"
                      >
                        + {{ extra.price | customCurrency }}
                      </span>
                    </span>
                    <div
                      class="flex items-center justify-end gap-4 w-full max-w-[90px]"
                      *ngIf="isActive(extra)"
                    >
                      <ng-container
                        *ngIf="extraCategory.category.max > 1; else radio"
                      >
                        <div class="flex justify-between w-full">
                          <button
                            class="button circle--icon bg-primary small !w-[24px] h-[24px]"
                            [ngClass]="{
                              disabled:
                                getExtraAmmount(
                                  extra._id,
                                  extraCategory.category._id
                                ) === 0
                            }"
                            [disabled]="
                              getExtraAmmount(
                                extra._id,
                                extraCategory.category._id
                              ) === 0
                            "
                            (click)="
                              addExtra(
                                extra,
                                extraCategory.category._id,
                                'counter',
                                true
                              )
                            "
                          >
                            <i class="fa-solid fa-minus !text-[14px]"></i>
                          </button>
                          <span class="text-[14px] text-black">{{
                            getExtraAmmount(
                              extra._id,
                              extraCategory.category._id
                            )
                          }}</span>
                          <button
                            class="button circle--icon bg-primary small !w-[24px] h-[24px]"
                            [ngClass]="{
                              disabled:
                                getExtraAmmount(
                                  extra._id,
                                  extraCategory.category._id
                                ) === extra.max ||
                                extraCategory.selectedExtrasCount ===
                                  extraCategory.category.max
                            }"
                            [disabled]="
                              getExtraAmmount(
                                extra._id,
                                extraCategory.category._id
                              ) === extra.max ||
                              extraCategory.selectedExtrasCount ===
                                extraCategory.category.max
                            "
                            (click)="
                              addExtra(
                                extra,
                                extraCategory.category._id,
                                'counter',
                                false
                              )
                            "
                          >
                            <i class="fa-solid fa-plus !text-[14px]"></i>
                          </button>
                        </div>
                      </ng-container>
                      <ng-template #radio>
                        <ng-container
                          *ngIf="
                            (extraCategory.category.max === 1 &&
                              extraCategory.category.min === 0) ||
                              (extraCategory.category.max === 1 &&
                                extraCategory.category.min === 1);
                            else check
                          "
                        >
                          <div class="radio-form-control">
                            <input
                              type="radio"
                              class=""
                              [id]="i"
                              [name]="extraCategory.category._id"
                              (change)="
                                addExtra(
                                  extra,
                                  extraCategory.category._id,
                                  'radio'
                                )
                              "
                            />
                          </div>
                        </ng-container>
                      </ng-template>
                      <ng-template #check>
                        <div class="checkbox-form-control">
                          <input
                            type="checkbox"
                            class=""
                            [id]="i"
                            [name]="extraCategory.category._id"
                            (change)="
                              addExtra(
                                extra,
                                extraCategory.category._id,
                                'checkbox'
                              )
                            "
                          />
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="flex flex-col w-full gap-4">
          <textarea
            placeholder="Notas"
            class="w-full border-gray-300 mt-2 mb-4 p-2 outline-primary lg:flex"
            name="commentary"
            id="commentary"
            rows="3"
            [(ngModel)]="commentary"
          >
          </textarea>
        </div>
      </div>
      <div
        class="w-full flex-col hidden lg:flex lg:w-[371px] gap-2 border-t-2 border-slate-300"
      >
        <div class="w-full flex items-center justify-between">
          <div
            class="flex items-center justify-center gap-8 text-[20px] font-bold text-primary"
          >
            <button
              class="p-4"
              [disabled]="quantity === 1"
              (click)="quantity = quantity - 1"
            >
              <i class="fa-solid fa-minus"></i>
            </button>
            <span class="text-black">{{ quantity }}</span>
            <button
              class="p-4"
              [disabled]="
                (hasVariant &&
                  (quantity === variantStock || variantStock === 0)) ||
                (product.cartLimitEnabled && quantity === product.cartLimit)
              "
              (click)="addQuantity()"
            >
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
          <span class="text-[20px] font-bold text-primary">{{
            calculateProductTotal() * quantity | customCurrency
          }}</span>
        </div>
        <button
          class="button hidden lg:block w-full bg-primary disabled:bg-slate-400 disabled:border-slate-400 disabled:opacity-40 disabled:cursor-not-allowed disabled:text-slate-900"
          (click)="addToShoppingBag()"
          *ngIf="!loading"
          [disabled]="product.show_in_store && !product.active"
        >
          {{
            product.show_in_store && !product.active
              ? "Agotado"
              : "Agregar al carrito"
          }}
        </button>
      </div>
    </div>
  </div>
  <div
    class="w-full h-[112px] flex flex-col border-t-2 border-slate-300 lg:hidden"
  >
    <div class="w-full flex items-center justify-between">
      <div
        class="flex items-center justify-center gap-8 text-[20px] font-bold text-primary"
      >
        <button
          class="p-4"
          [disabled]="quantity === 1"
          (click)="quantity = quantity - 1"
        >
          <i class="fa-solid fa-minus"></i>
        </button>
        <span class="text-black">{{ quantity }}</span>
        <button
          class="p-4"
          [disabled]="
            (hasVariant && (quantity === variantStock || variantStock === 0)) ||
            (product.cartLimitEnabled && quantity === product.cartLimit)
          "
          (click)="addQuantity()"
        >
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <span class="text-[20px] font-bold text-primary">{{
        calculateProductTotal() * quantity | customCurrency
      }}</span>
    </div>
    <button
      class="button bg-primary w-full disabled:bg-slate-400 disabled:border-slate-400 disabled:opacity-40 disabled:cursor-not-allowed disabled:text-slate-900"
      (click)="addToShoppingBag()"
      *ngIf="!loading"
      [disabled]="product.show_in_store && !product.active"
    >
      {{
        product.show_in_store && !product.active
          ? "Agotado"
          : "Agregar al carrito"
      }}
    </button>
  </div>
</div>
<div *ngIf="loading" class="page p-8 pb-4 gap-10 lg:!flex-row">
  <div class="animate-pulse w-full h-full rounded-md bg-gray-200"></div>
  <div class="flex flex-col h-full w-full gap-10">
    <div class="animate-pulse w-full h-full rounded-md bg-gray-200"></div>
    <div class="animate-pulse w-full h-[120px] rounded-md bg-gray-200"></div>
  </div>
</div>
